import styled  from "styled-components";
import { colors } from "../../constants/colors";
import { spacing } from "../../constants/spacing";
import "@fontsource/playfair-display";

export const Headline = styled.h1`
  span {
    font-size: 3rem;
  }
  display: inline-block;
  font-size: 1.2rem;

  @media (max-width: 700px) {
    max-width: ${spacing.space8xl};
    margin: ${spacing.spaceSm} auto ${spacing.spaceSm} auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    text-align: center;

    span {
      font-size: 1.8rem;
    }
  }
`;

export const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: ${spacing.spaceXl};

  .timeline-row + .timeline-row {
  }

  .timeline-row {
    //position: absolute;
    display: flex;
    // min-height: ${spacing.space4xl};

    .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-right: 20px;
      width: 120px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 ${spacing.spaceSm} ${spacing.space2xs};

        border-style: solid none;
        border-color: ${colors.primary.light};
        font-size: 2.5rem;
        font-family: "Playfair Display", "Bodoni", serif;
        color: ${colors.primary};
      }

      div {
        height: 100%;
        width: 2px;
        background-color: ${colors.greys["200"]};
      }

      .last {
        display: none;
      }
    }

    .right {
      display: flex;
      max-width: ${spacing.space8xl};
      height: min-content;
      padding: ${spacing.spaceSm};
      margin: 0 0 ${spacing.spaceXs} 0;
      font-size: 1.3rem;

      background-color: hsla(139, 83%, 14%, 25%);
    }
  }

  @media (max-width: 700px) {
    margin-top: 0;

    .timeline-row {
      flex-direction: column;

      .left {
        width: auto;
        align-items: flex-start;

        span {
          border-style: none;
          padding: 0 ${spacing.space2xs} 0 0;
        }
      }
    }
  }
`;
