import React from "react";
import Layout from "../components/Layout";
import { Headline, Timeline } from "../components/Geschichte/styled";
import events from "../components/Geschichte/events.json";
import { spacing } from "../constants/spacing";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Geschichte({ location }) {
  if (typeof window !== "undefined") {
    AOS.init();
  }

  return (
    <Layout
      location={location}
      title={"Gartenbau Dannenfelser | Geschichte"}
      description={
        "2010: Gründung des Einmannbetriebes, durch den gleichnamigen Geschäftsführer Udo Dannenfelser, Fachkraft im Garten- und Landschaftsbau mit langjähriger Berufserfahrung."
      }
    >
      <Headline>
        <span>Gartenbau Udo Dannenfelser - </span>
        <span className={"lightgreen"}>Kompetenz in Grün</span>
      </Headline>

      <Timeline>
        {events.map((event, index) => {
          return (
            <div
              className={"timeline-row"}
              data-aos="fade-up"
              data-aos-offset="30"
              style={{
                minHeight: index === 0 ? "200px" : spacing.space4xl,
              }}
            >
              <div className={"left"}>
                <span>{event.year}</span>
                <div className={index === events.length - 1 ? "last" : ""} />
              </div>
              <div className={"right"}>{event.text}</div>
            </div>
          );
        })}
      </Timeline>
    </Layout>
  );
}
